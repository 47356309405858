export default class TabOpen {
  constructor() {
    this.setup();
  }
  setup() {
    // data-tab="開きたいTabのID" をhtmlで指定する

    $('[data-tab]').on('click', function() {
      const tabId = $(this).data('tab');
      activaTab(tabId);
    });
    function activaTab(tab) {
      $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    }
  }
}
