export default class IconToggle {
  constructor() {
    this.setup();
  }
  setup() {
    const favoriteBtn = document.getElementsByClassName('js-iconToggle');

    for (let i = 0; i < favoriteBtn.length; i++) {
      favoriteBtn[i].addEventListener('click', e => {
        const target = e.currentTarget;
        if (target.getAttribute('data-active') == 'true') {
          target.setAttribute('data-active', 'false');
          target.setAttribute('aria-pressed', 'false');
        } else {
          target.setAttribute('data-active', 'true');
          target.setAttribute('aria-pressed', 'true');
        }
      });
    }
  }
}
