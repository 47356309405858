import SmoothScroll from 'smooth-scroll';

export default class Smoothscroll {
  constructor() {
    this.setup();
  }
  setup() {
    SmoothScroll('a[href*="#"]', {
      ignore: '[data-scroll-ignore]',
      header: '[data-scroll-header]',
      topOnEmptyHash: true,
      speed: 600,
      speedAsDuration: true,
      easing: 'easeInOutCubic',
      updateURL: false
    });
  }
}
