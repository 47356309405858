import Cleave from 'cleave.js';

export default class CreditForm {
  constructor() {
    this.setup();
  }
  setup() {
    // card number
    this.ccNum = document.querySelectorAll('.js-creditInput');
    Array.from(this.ccNum).forEach(elm => {
      new Cleave(elm, {
        creditCard: true,
        onCreditCardTypeChanged: function(type) {
          const brand = elm.closest('.input-group').querySelector('.js-cardBrand');
          if (type == 'unknown') {
            brand.removeAttribute('data-cardbrand');
          } else {
            brand.setAttribute('data-cardbrand', type);
          }
        }
      });
    });
  }
}
