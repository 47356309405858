import Swiper from 'swiper';

export default class Slider {
  constructor() {
    window.addEventListener('load', () => {
      this.setup();
    });
  }
  setup() {
    const elmSwiper = document.querySelectorAll('.js-ec-slider, .js-cardSlider');
    const elmPager = document.querySelectorAll('.js-ec-pagination, .js-cardSlider-pagination');
    const elmBtnPrev = document.querySelectorAll('.js-ec-button-prev, .js-cardSlider-button-prev');
    const elmBtnNext = document.querySelectorAll('.js-ec-button-next, .js-cardSlider-button-next');
    if (elmSwiper.length > 0) {
      for (let i = 0; i < elmSwiper.length; i++) {
        elmSwiper[i].className += i;
        elmPager[i].className += i;
        elmBtnPrev[i].className += i;
        elmBtnNext[i].className += i;
        new Swiper('.js-ec-slider' + i + ':not(.-maxcol4)', {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 0,
          watchOverflow: true,
          pagination: {
            el: '.js-ec-pagination' + i,
            clickable: true,
            type: 'bullets'
          },
          navigation: {
            prevEl: '.js-ec-button-prev' + i,
            nextEl: '.js-ec-button-next' + i
          },
          breakpoints: {
            575: {
              // <= 575px（xs - sm）
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 0
            },
            767: {
              // <= 767px（sm - md）
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 0
            },
            991: {
              // <= 991px（md - lg）
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 0
            }
          }
        });
        new Swiper('.-maxcol4.js-ec-slider' + i, {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 0,
          watchOverflow: true,
          pagination: {
            el: '.js-ec-pagination' + i,
            clickable: true,
            type: 'bullets'
          },
          navigation: {
            prevEl: '.js-ec-button-prev' + i,
            nextEl: '.js-ec-button-next' + i
          },
          breakpoints: {
            575: {
              // <= 575px（xs - sm）
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 0
            },
            991: {
              // <= 991px（md - lg）
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 0
            }
          }
        });
        //
        new Swiper('.js-cardSlider' + i + ':not(.-maxcol4)', {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 0,
          watchOverflow: true,
          pagination: {
            el: '.js-cardSlider-pagination' + i,
            clickable: true,
            type: 'bullets'
          },
          navigation: {
            prevEl: '.js-cardSlider-button-prev' + i,
            nextEl: '.js-cardSlider-button-next' + i
          },
          breakpoints: {
            575: {
              // <= 575px（xs - sm）
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 0
            },
            767: {
              // <= 767px（sm - md）
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 0
            },
            991: {
              // <= 991px（md - lg）
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 0
            }
          }
        });
        new Swiper('.-maxcol4.js-cardSlider' + i, {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 0,
          watchOverflow: true,
          pagination: {
            el: '.js-cardSlider-pagination' + i,
            clickable: true,
            type: 'bullets'
          },
          navigation: {
            prevEl: '.js-cardSlider-button-prev' + i,
            nextEl: '.js-cardSlider-button-next' + i
          },
          breakpoints: {
            575: {
              // <= 575px（xs - sm）
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 0
            },
            991: {
              // <= 991px（md - lg）
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 0
            }
          }
        });
      }
    }
  }
}
