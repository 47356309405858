export default class RemoveElement {
  constructor() {
    this.removeEl = document.querySelectorAll('.js-removeElement');
    Array.from(this.removeEl).forEach(elm => {
      const removeBtn = elm.querySelectorAll('.js-removeBtn');
      Array.from(removeBtn).forEach(btn => {
        btn.addEventListener('click', function() {
          btn.closest('.js-removeElement').remove();
        });
      });
    });
  }
}
