export default class CheckboxAllcheck {
  constructor() {
    this.setup();
  }
  setup() {
    const allcheck = document.getElementsByClassName('js-all-check');

    for (let i = 0; i < allcheck.length; i++) {
      const check_el = allcheck[i].closest('.js-all-check-wrap').querySelectorAll('.js-all-check-child');

      const funcCheckAll = bool => {
        for (let i = 0; i < check_el.length; i++) {
          check_el[i].checked = bool;
        }
      };

      const funcCheck = () => {
        let count = 0;
        for (let i = 0; i < check_el.length; i++) {
          if (check_el[i].checked) {
            count += 1;
          }
          if (check_el.length === count) {
            check_el[i].closest('.js-all-check-wrap').querySelector('.js-all-check').checked = true;
          } else {
            check_el[i].closest('.js-all-check-wrap').querySelector('.js-all-check').checked = false;
          }
        }
      };

      allcheck[i].addEventListener(
        'click',
        () => {
          funcCheckAll(allcheck[i].checked);
        },
        false
      );

      for (let i = 0; i < check_el.length; i++) {
        check_el[i].addEventListener('click', funcCheck, false);
      }
    }
  }
}
