export default class ChatScroll {
  constructor() {
    window.addEventListener('load', () => {
      this.setup();
    });
  }

  setup() {
    const timeline = document.getElementById('js-chatScroll');

    if (timeline != null) {
      const timelineH = timeline.scrollHeight;
      timeline.scrollTop = timelineH;
    }
  }
}
