export default class sideColumnMenu {
  constructor() {
    this.setup();
  }
  setup() {
    const hamburgerMenu = document.getElementById('js-menu-btn');

    const sidebarMenu = document.getElementById('js-sidemenu-btn');
    const sidelink = document.querySelectorAll('.l-sideColumn__side li a');
    const root = document.documentElement;

    if (sidebarMenu != null) {
      sidebarMenu.addEventListener('click', e => {
        const target = e.currentTarget;
        if (target.classList.contains('-open')) {
          target.classList.remove('-open');
          target.classList.add('-close');
          root.classList.add('sidemenu-close');
          root.classList.remove('sidemenu-open');
          $('.l-sideColumn__side .collapse').collapse('hide');
        } else {
          target.classList.remove('-close');
          target.classList.add('-open');
          root.classList.add('sidemenu-open');
          root.classList.remove('sidemenu-close');
          if (hamburgerMenu) {
            if (hamburgerMenu.classList.contains('-open')) {
              hamburgerMenu.classList.remove('-open');
              hamburgerMenu.classList.add('-close');
              root.classList.add('menu-close');
              root.classList.remove('menu-open');
            }
          }
        }
      });

      for (let i = 0; i < sidelink.length; i++) {
        sidelink[i].addEventListener('click', function() {
          if (root.classList.contains('-open')) {
            sidebarMenu.classList.remove('-open');
            sidebarMenu.classList.add('-close');
            root.classList.add('sidemenu-close');
            root.classList.remove('sidemenu-open');
          } else {
            sidebarMenu.classList.remove('-close');
            sidebarMenu.classList.add('-open');
            root.classList.add('sidemenu-open');
            root.classList.remove('sidemenu-close');
          }
        });

        window.addEventListener('DOMContentLoaded', () => {
          if (sidelink[i].classList.contains('active')) {
            sidelink[i].closest('.collapse').classList.add('show');
            sidelink[i].closest('.collapse').previousElementSibling.classList.remove('collapsed');
          }
        });
      }

      // matchMediaの設定
      const mqs = window.matchMedia('(min-width: 1170px)');

      // addListener()はmatchMediaの指定に沿ってコールバックを実行
      mqs.addListener(changeMediaQuery);

      function changeMediaQuery(mqs) {
        if (mqs.matches === true) {
          // 1170px以上
          sidebarMenu.classList.remove('-close');
          sidebarMenu.classList.add('-open');
          root.classList.add('sidemenu-open');
          root.classList.remove('sidemenu-close');
          // $('.l-sideColumn__side .collapse').collapse();
        } else {
          // 1170px未満
          sidebarMenu.classList.remove('-open');
          sidebarMenu.classList.add('-close');
          root.classList.add('sidemenu-close');
          root.classList.remove('sidemenu-open');
          $('.l-sideColumn__side .collapse').collapse('hide');
        }
      }

      // 初期読み込み時用
      changeMediaQuery(mqs);
    }
  }
}
