import ObjectFitImages from 'object-fit-images';

export default class ObjectFit {
  constructor() {
    window.addEventListener('load', () => {
      this.setup();
    });
  }
  setup() {
    ObjectFitImages('img.object-fit');
  }
}
