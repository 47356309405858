export default class TextCollapse {
  constructor() {
    this.setup();
    textCollapse();
  }
  setup() {
    window.textCollapse = () => {
      const textContents = document.getElementsByClassName('js-textCollapse');
      if (textContents) {
        for (let i = 0; i < textContents.length; i++) {
          const clamp = textContents[i].dataset.clamp;
          const applied = textContents[i].dataset.applied;
          const textHeight = textContents[i].clientHeight;

          if (applied === 'true' || textHeight === 0) {
            continue;
          } else {
            textContents[i].dataset.applied = true;
          }

          const moreButton = textContents[i].nextElementSibling;
          const closeText = moreButton.dataset.closetext;
          const openText = moreButton.dataset.opentext;
          let lineHeight = getComputedStyle(textContents[i]).getPropertyValue('line-height');
          lineHeight = lineHeight.replace(/[^-\d\.]/g, '');

          if (textHeight > lineHeight * clamp) {
            textContents[i].style.height = `${lineHeight * clamp}px`;
          } else {
            moreButton.style.display = 'none';
          }

          moreButton.addEventListener('click', () => {
            // textContents[i]だと、textContent要素追加/削除時に対象がずれる。
            moreButton.previousElementSibling.classList.toggle('is-open');
            if (closeText) {
              const moreButtonText = moreButton.textContent;
              if (moreButtonText == openText) {
                moreButton.innerText = closeText;
              } else {
                moreButton.innerText = openText;
              }
            }
          });
        }
      }
    };
  }
}
