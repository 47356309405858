export default class Hamburger {
  constructor() {
    this.setup();
  }
  setup() {
    const hamburgerMenu = document.getElementById('js-menu-btn');
    const menuContentsBg = document.getElementById('js-menuContentsBg');
    const sidebarMenu = document.getElementById('js-sidemenu-btn');
    const root = document.documentElement;

    if (hamburgerMenu != null) {
      hamburgerMenu.addEventListener('click', e => {
        const target = e.currentTarget;
        if (target.classList.contains('-open')) {
          target.classList.remove('-open');
          target.classList.add('-close');
          root.classList.add('menu-close');
          root.classList.remove('menu-open');
        } else {
          target.classList.remove('-close');
          target.classList.add('-open');
          root.classList.add('menu-open');
          root.classList.remove('menu-close');
          if (sidebarMenu) {
            if (sidebarMenu.classList.contains('-open')) {
              sidebarMenu.classList.remove('-open');
              sidebarMenu.classList.add('-close');
              root.classList.add('sidemenu-close');
              root.classList.remove('sidemenu-open');
              $('.l-sideColumn__side .collapse').collapse('hide');
            }
          }
        }
      });

      menuContentsBg.addEventListener('click', () => {
        hamburgerMenu.classList.remove('-open');
        hamburgerMenu.classList.add('-close');
        root.classList.add('menu-close');
        root.classList.remove('menu-open');
      });
    }
  }
}
