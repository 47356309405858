import Cropper from 'cropperjs';

class CropperCotainer {
  constructor(container) {
    // console.log(this.fileType);
    this.stage = container.querySelector('.cropper-stage');
    this.fileInput = container.querySelector('.cropper-input');
    this.btnFileInput = this.fileInput.closest('.btn');
    this.btnFixImage = container.querySelector('.btn-fix-image');
    this.btnFixText = container.querySelector('.btn-fix-text');
    this.btnDeleteImage = container.querySelector('.btn-delete-image');
    this.btnRotateImage = container.querySelector('.btn-rotate');
    this.btnRotateBackImage = container.querySelector('.btn-rotate-back');
    this.croppedData = container.querySelector('.cropped-data');
    this.dataAspectRatio = container
      .getAttribute('data-aspectRatio')
      .split('/')
      .map(Number);
    this.fileInput.value = null;
    this.stage.style.paddingBottom = (this.dataAspectRatio[1] / this.dataAspectRatio[0]) * 100 + '%';

    this.fileInput.addEventListener('change', () => {
      this.stage.innerHTML = '';
      // Object.keys(this.fileInput.files).forEach(key => {
      this.stage.style.paddingBottom = ((this.dataAspectRatio[1] * 1) / this.dataAspectRatio[0]) * 100 + '%';
      // this.stage.classList.add('edit');
      this.filereader = new FileReader();
      this.filereader.readAsDataURL(this.fileInput.files[0]);
      this.filereader.onload = () => {
        this.setCropper();
      };
      this.fileInput.value = null;
      // });
    });
    this.btnFixImage.addEventListener('click', () => {
      this.fixImage();
    });
    this.btnDeleteImage.addEventListener('click', () => {
      this.deleteImage();
    });
    this.btnRotateImage.addEventListener('click', () => {
      this.cropper.rotate(15);
    });
    this.btnRotateBackImage.addEventListener('click', () => {
      this.cropper.rotate(-15);
    });
    if (this.stage.classList.contains('crop-circle')) {
      this.isCropCircle = true;
    }
  }
  setCropper() {
    const img = document.createElement('img');
    this.btnFileInput.classList.remove('btn-primary');
    this.btnFileInput.classList.add('btn-secondary');
    this.btnFixImage.style.display = 'inline-block';
    this.btnFixText.style.display = 'block';
    this.btnDeleteImage.style.display = 'none';
    this.btnRotateImage.style.display = 'inline-flex';
    this.btnRotateBackImage.style.display = 'inline-flex';
    document.querySelectorAll(".crop-submit").forEach(e => e.disabled = true);
    img.setAttribute('src', this.filereader.result);
    this.stage.appendChild(img);
    // this.croppedData.value = this.filereader.result; //セット時にオリジナル画像をcroppedDataに設定
    this.cropper = new Cropper(img, {
      aspectRatio: this.dataAspectRatio[0] / this.dataAspectRatio[1],
      viewMode: 3,
      dragMode: 'move',
      // preview: this.stage,
      toggleDragModeOnDblclick: false,
      rotatable: true
      // crop(event) {
      //   console.log(event.detail.x);
      //   console.log(event.detail.y);
      //   console.log(event.detail.width);
      //   console.log(event.detail.height);
      //   console.log(event.detail.rotate);
      //   console.log(event.detail.scaleX);
      //   console.log(event.detail.scaleY);
      // }
    });
  }
  fixImage() {
    this.btnFixImage.style.display = 'none';
    this.btnFixText.style.display = 'none';
    this.btnDeleteImage.style.display = 'inline-block';
    this.btnRotateImage.style.display = 'none';
    this.btnRotateBackImage.style.display = 'none';
    document.querySelectorAll(".crop-submit").forEach(e => e.disabled = false);
    // this.stage.classList.remove('edit');
    this.stage.style.paddingBottom = (this.dataAspectRatio[1] / this.dataAspectRatio[0]) * 100 + '%';
    this.cropper
      .getCroppedCanvas({
        width: this.dataAspectRatio[0],
        height: this.dataAspectRatio[1],
        // minWidth: 256,
        // minHeight: 256,
        // maxWidth: 4096,
        // maxHeight: 4096,
        fillColor: '#fff',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'
      })
      .toBlob(
        blob => {
          this.cropper.destroy();
          const newImg = document.createElement('img');
          this.stage.innerHTML = '';
          const reader = new FileReader();
          reader.onload = e => {
            newImg.src = e.target.result;
            this.croppedData.value = e.target.result;
            // console.log(e.target.result);
          };
          this.stage.appendChild(newImg);
          reader.readAsDataURL(blob);
        },
        'image/jpeg',
        0.95
      );
  }
  deleteImage() {
    this.btnFileInput.classList.remove('btn-secondary');
    this.btnFileInput.classList.add('btn-primary');
    this.btnDeleteImage.style.display = 'none';
    this.stage.classList.remove('edit');
    this.stage.innerHTML = '';
    this.croppedData.value = '';
    document.querySelectorAll(".crop-submit").forEach(e => e.disabled = true);
  }
}

export default class FileUploadCropper {
  constructor() {
    window.addEventListener('load', () => {
      this.setup();
    });
  }

  setup() {
    const fileUploadCropper = document.querySelectorAll('.c-file-upload-cropper');
    fileUploadCropper.forEach(container => {
      new CropperCotainer(container);
    });
  }
}
