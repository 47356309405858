import 'intersection-observer';

export default class FixedInContents {
  constructor() {
    const fixedInContentsObserve = () => {
      const fixedElement = document.querySelector('.js-fixedInContents');
      const unFixedtarget = document.querySelector('.footer');

      if (!fixedElement || !unFixedtarget) return;

      const observeHandler = entries => {
        fixedElement.setAttribute('data-fixed', !entries[0].isIntersecting);
      };

      const unFixedObserver = new window.IntersectionObserver(observeHandler);
      unFixedObserver.observe(unFixedtarget);
    };

    window.addEventListener('DOMContentLoaded', fixedInContentsObserve, false);
  }
}
